export const tabs_ua = [
  'Про\u00A0БЦ',
  'Поверх\u00A01',
  'Поверх\u00A02',
  'Поверх\u00A03',
  'Поверх\u00A04',
  'Поверх\u00A05',
];

export const tabs_en = [
  'About',
  'Ground\u00A0floor',
  '1\u00A0floor',
  '2\u00A0floor',
  '3\u00A0floor',
  '4\u00A0floor',
];

export const plug_ua = {
  noSpace: ['/images/availableOffices/plugs/no-offices-ua.png'],
  noPhoto: ['/images/availableOffices/plugs/prepare-photos-ua.png'],
};

export const plug_en = {
  noSpace: ['/images/availableOffices/plugs/no-offices-en.png'],
  noPhoto: ['/images/availableOffices/plugs/prepare-photos-en.png'],
};

export const floors = [
  {
    name: 'Поверх\u00A01',
    plan: {
      ua: './images/floorPlans/floor-1-ua.png',
      en: './images/floorPlans/floor-1n-en.png',
    },
    images: [
      '/images/availableOffices/floor-1/A-1-1.jpg',
      '/images/availableOffices/floor-1/A-1-2.jpg',
      '/images/availableOffices/floor-1/A-1-3.jpg',
      '/images/availableOffices/floor-1/A-1-4.jpg',
    ],
  },
  {
    name: 'Поверх\u00A02',
    plan: {
      ua: './images/floorPlans/floor-2-ua.jpg',
      en: './images/floorPlans/floor-2-en.jpg',
    },

    images: [
      '/images/availableOffices/floor-2/B-1-1.jpg',
      '/images/availableOffices/floor-2/B-1-2.jpg',
      '/images/availableOffices/floor-2/B-1-3.jpg',
      '/images/availableOffices/floor-2/B-1-4.jpg',
    ],
  },
  {
    name: 'Поверх\u00A03',
    plan: {
      ua: './images/floorPlans/floor-3.jpg',
      en: './images/floorPlans/floor-3en.jpg',
    },
    images: [
      '/images/availableOffices/floor-3/c-1.jpg',
      '/images/availableOffices/floor-3/c-2.jpg',
    ],
  },
  {
    name: 'Поверх\u00A04',
    plan: {
      ua: './images/floorPlans/floor-4.jpg',
      en: './images/floorPlans/floor-4-en.jpg',
    },
    images: [
      '/images/availableOffices/floor-4/d-1-1.jpg',
      '/images/availableOffices/floor-4/d-1-2.jpg',
      '/images/availableOffices/floor-4/d-2.jpg',
      
    ],
  },
  {
    name: 'Поверх\u00A05',
    plan: {
      ua: './images/floorPlans/floor-5.jpg',
      en: './images/floorPlans/floor-5-en.jpg',
    },
    images: [
      '/images/availableOffices/floor-5/f-1.jpg',
      '/images/availableOffices/floor-5/f-2.jpg',
      '/images/availableOffices/floor-5/f-3.jpg',
      '/images/availableOffices/floor-5/f-4.jpg',
      '/images/availableOffices/floor-5/f-5.jpg',
      '/images/availableOffices/floor-5/f-6.jpg',
      '/images/availableOffices/floor-5/f-7.jpg',
    ],
  },
];
